import React from "react";
//Router
import { Switch, Route, useLocation } from "react-router-dom";
//Components
import Nav from "./components/Nav";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
//Styles
import GlobalStyles from "./styles/GlobalStyles";
//Animate
import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();
  // console.log(location);
  return (
    <div className="App">
      <GlobalStyles />
      <Nav />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.key}>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/services" exact>
            <Services />
          </Route>
          <Route path="/pricing" exact>
            <Pricing />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
        </Switch>
      </AnimatePresence>
    </div>
  );
}

export default App;
