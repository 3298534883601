import React from "react";
//Components
import { Container } from "../../styles/GlobalLayout";
//Styled
import styled from "styled-components";
//Animation
import { motion } from "framer-motion";

const HomeHireMe = () => {
  return (
    <HireMeSection>
      <StyledContainer>
        <HireMeContent>
          <h2>Hire Me.</h2>
          <p>
            I am available for freelance work. Connect with me via phone:{" "}
            <a href="tel:+919957177921">+91-9957177921</a> or email:&nbsp;
            <a href="mailto:contact@binitdev.tech">contact@binitdev.tech</a>
          </p>
          <form className="hire-contact-form">
            <div>
              <input
                type="text"
                name="name"
                placeholder="Your Name *"
                required
              />
            </div>
            <div>
              <input
                name="email"
                type="email"
                placeholder="Your Email *"
                required
              />
            </div>
            <div>
              <input
                type="text"
                name="subject"
                placeholder="Write a subject *"
                required
              />
            </div>
            <div>
              <textarea name="message" rows="5" cols="70" required></textarea>
            </div>
            <div>
              <button type="button" name="submitbtn">
                Submit
              </button>
            </div>
          </form>
        </HireMeContent>
        <HireMeImage></HireMeImage>
      </StyledContainer>
    </HireMeSection>
  );
};
const HireMeSection = styled(motion.section)`
  background-color: var(--dark-background-color);
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media (min-width: 992px) {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
`;
const StyledContainer = styled(Container)`
  margin: 0 auto;
  display: grid;
  /* flex-direction: row;
  justify-content: start;
  align-items: center; */
  grid-template-columns: minmax(calc(320px - 2rem), 90%);
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  color: #fff;
  padding: 2rem 1rem;
  @media (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
`;
const HireMeContent = styled(motion.div)`
  order: 2;
  width: calc(320px - 2rem);
  @media (min-width: 992px) {
    order: 1;
    width: 100%;
  }
  h2 {
    letter-spacing: 0.2rem;
    font-weight: 700;
    @media (min-width: 992px) {
      font-size: 4rem;
    }
  }
  & > p {
    color: rgba(198, 201, 216, 0.75);
    line-height: 30px;
    font-size: 1rem;
    @media (min-width: 992px) {
      font-size: 1.1rem;
    }
  }
  & > form {
    margin-top: 2rem;
    input,
    textarea {
      width: 100%;
      padding: 0.8rem 1.2rem;
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 5px;
      margin: 0.3rem 0 0.5rem;
      background: transparent;
      color: rgba(198, 201, 216, 0.75);
      letter-spacing: 0.1px;
      outline: none;
      font-size: 1rem;
    }
    button {
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      text-transform: uppercase;
      padding: 0.8rem 2.5rem;
      background-color: var(--pink-brand-color);
      color: #fff;
      border: 1px solid var(--pink-brand-color);
      border-radius: 4px;
      transition: all 0.5s ease;
      letter-spacing: 2px;
      &:hover {
        background-color: transparent;
        color: var(--pink-brand-color);
        transform: translateY(-0.2rem);
      }
    }
  }
`;
const HireMeImage = styled(motion.div)`
  background-color: #ebebeb;
  order: 1;
  height: 200px;
  width: calc(320px - 2rem);
  @media (min-width: 992px) {
    order: 2;
    width: 100%;
    height: 100%;
  }
`;
export default HomeHireMe;
