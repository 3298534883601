import React from "react";
//Components
import { Container } from "../../styles/GlobalLayout";
import PortfolioList from "../portofolio/PortfolioList";
//Styles
import styled from "styled-components";
//Animation
import { motion } from "framer-motion";

const HomePortfolioSection = () => {
  return (
    <PortfolioSection>
      <StyledContainer>
        <h4>Portfolio</h4>
        <h2>Some of my recent projects</h2>
        <StyledPortfolioContainer className="grid grid-col-gap-6 grid-row-gap-1">
          <PortfolioList
            stylevariation="text-center mt--40"
            column=""
            item="6"
          />
        </StyledPortfolioContainer>
      </StyledContainer>
    </PortfolioSection>
  );
};

const PortfolioSection = styled(motion.section)`
  background-color: var(--gray-background-color);
`;

const StyledContainer = styled(Container)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  & > h4 {
    color: #333;
    background-color: var(--primary-brand-color);
    font-size: 1rem;
    padding: 0.2rem 0.8rem;
    width: max-content;
    text-align: center;
    font-weight: normal;
    text-transform: capitalize;
    border-radius: 2px;
  }
  h2 {
    text-align: center;
    margin-top: 1rem;
    text-transform: capitalize;
    margin-bottom: 1.7rem;
    @media screen and (max-width: 991px) {
      font-size: 1.8rem;
      padding: 0 1rem;
    }
  }
`;
const StyledPortfolioContainer = styled(motion.div)`
  grid-template-columns: minmax(calc(320px - 35px), 1fr);
  .portfolio {
    min-height: 550px;
  }
  @media (min-width: 768px) {
    grid-template-columns: minmax(320px, 1fr) minmax(320px, 1fr);
  }
  @media (min-width: 992px) {
    grid-template-columns: minmax(320px, 1fr) minmax(320px, 1fr) minmax(
        320px,
        1fr
      );
    width: 100%;
    padding: 0 2rem;
    .portfolio {
      min-height: 500px;
    }
  }
`;

export default HomePortfolioSection;
