import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
//Images
import logo from "../img/logo.svg";
//Styles
import styled from "styled-components";
//Animation
import { motion } from "framer-motion";

const Nav = () => {
  const { pathname } = useLocation();
  return (
    <StyledNav>
      <Logo>
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </Logo>
      <StyledNavBlock>
        <NavLinks>
          <li>
            <Link to="/">Home</Link>
            <Line
              transition={{ duration: 0.75 }}
              initial={{ width: "0%" }}
              animate={{ width: pathname === "/" ? "100%" : "0%" }}
            />
          </li>
          <li>
            <Link to="/#">About</Link>
            <Line
              initial={{ width: "0%" }}
              animate={{ width: pathname === "/services" ? "100%" : "0%" }}
              transition={{ duration: 0.75 }}
            />
          </li>
          <li>
            <Link to="/#">Services</Link>
            <Line
              initial={{ width: "0%" }}
              transition={{ duration: 0.75 }}
              animate={{ width: pathname === "/pricing" ? "100%" : "0%" }}
            />
          </li>
          <li>
            <Link to="/#">Projects</Link>
            <Line
              initial={{ width: "0%" }}
              transition={{ duration: 0.75 }}
              animate={{ width: pathname === "/contact" ? "100%" : "0%" }}
            />
          </li>
        </NavLinks>
        <StyledHireMeBtn href="#">Hire Me</StyledHireMeBtn>
        <StyledHamburgerIcon />
      </StyledNavBlock>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  background-color: var(--dark-background-color);
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  grid-template-columns: minmax(180px, 0.49fr) minmax(400px, 0.51fr);
  gap: 0rem;
  color: #fff;
  padding: 1rem 3rem 1rem 0.2rem;
  @media (max-width: 991px) {
    grid-template-columns: minmax(10px, 0.25fr) minmax(100px, 1fr);
    padding: 1rem 1rem 1rem 1rem;
  }
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
  & > a {
    line-height: 0.5;
  }
  & > a > img {
    margin-right: 1rem;
    width: 3.5rem;
  }
  @media (max-width: 991px) {
    margin-left: 0;
    & > a > img {
      margin-right: 0rem;
    }
  }
`;
const StyledNavBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    justify-content: end;
  }
`;
const NavLinks = styled.ul`
  li {
    display: inline-block;
    margin-right: 2rem;
    font-weight: 500;
    position: relative;
    a {
      font-size: 1.125rem;
    }
  }
  @media (max-width: 991px) {
    display: none;
  }
`;
const Line = styled(motion.div)`
  height: 0.25rem;
  background: var(--primary-brand-color);
  width: 0%;
  position: absolute;
  bottom: -20%;
  left: 0%;
`;
const StyledHireMeBtn = styled.a`
  background-color: #01cdb5;
  color: #fffafa;
  font-size: 1.125rem;
  border: 3px solid #fffafa;
  border-radius: 21px;
  padding: 0.2rem 1.5rem;
  font-weight: 600;
  @media (max-width: 567px) {
    display: none;
  }
`;
const StyledHamburgerIcon = styled(HiMenu)`
  display: block;
  text-align: right;
  width: 2rem;
  height: 2rem;
  @media (min-width: 992px) {
    display: none;
  }
`;
export default Nav;
