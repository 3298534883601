import React, { useState } from "react";
//Styles
import styled from "styled-components";
//Animation
import { motion } from "framer-motion";
import { titleAnim } from "../../Animation";
//Images
import mypic from "../../img/me.jpg";
//Icons
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
const HomeIntroSection = () => {
  const [isShownFirst, setIsShownFirst] = useState(true);
  const [isShownSecond, setIsShownSecond] = useState(false);
  const ToggleBannerText = () => {
    setIsShownFirst(!isShownFirst);
    setIsShownSecond(!isShownSecond);
  };

  return (
    <IntroSection>
      <IntroSectionFirstPart>
        <ProfileImageContainer>
          <img src={mypic} alt="mypic" />
        </ProfileImageContainer>
      </IntroSectionFirstPart>
      <IntroSectionSecondPart>
        <h5>LOVE WHAT YOU DO</h5>
        <motion.h1>Hi, I'm Binit Demta</motion.h1>
        <h4 className="designation">
          {isShownFirst && (
            <motion.span
              variants={titleAnim}
              initial="hidden"
              animate="show"
              exit="exit"
              onAnimationComplete={(definition) => {
                ToggleBannerText();
              }}
            >
              Full Stack
            </motion.span>
          )}
          {isShownSecond && (
            <motion.span
              variants={titleAnim}
              initial="hidden"
              animate="show"
              exit="exit"
              onAnimationComplete={(definition) => {
                ToggleBannerText();
              }}
            >
              Web Developer.
            </motion.span>
          )}
        </h4>
        <h4>based in India.</h4>
        <StyledLink href="#">Contact Me</StyledLink>
      </IntroSectionSecondPart>
    </IntroSection>
  );
};

const IntroSection = styled(motion.section)`
  background-color: #f8f9fc;
  height: 100%;
  min-height: 100%;
  padding: 2rem 0.3rem 2rem;
  margin: 0 auto;
  flex-direction: column;
  /* display: grid;
  grid-template-columns: minmax(320px, 0.2fr) minmax(320px, 0.3fr);
  grid-column-gap: 0rem;
  grid-row-gap: 3rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  @media (min-width: 992px) {
    /* grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); */
    padding: 7rem 2rem 2rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 88.5px);
    & > div {
      min-height: 480px;
    }
  }
`;
const IntroSectionFirstPart = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  /* margin-right: 2rem; */
  @media (min-width: 992px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;
  }
`;

const IntroSectionSecondPart = styled(motion.div)`
  /* padding-left: 2rem; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  h5 {
    color: #333;
    font-size: 1.5rem;
    font-family: "Lato", sans-serif;
    position: relative;
    letter-spacing: 0.22em;
    line-height: 3rem;
    font-weight: 700;
    width: 100%;
    @media screen and (max-width: 480px) {
      letter-spacing: 0.3rem;
    }
  }
  h1 {
    color: #1f1f25;
    /* font-family: "Poppins", sans-serif; */
    font-family: "Montserrat", sans-serif;
    font-size: 3.375rem;
    font-weight: 900;
    letter-spacing: 0.07em;
    line-height: 4.125rem;
    span {
      color: #fff;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.5rem;
    }
  }
  h4 {
    color: #333;
    letter-spacing: 0.07em;
    font-weight: 900;
    margin-bottom: 0.3rem;
    font-size: 3.375rem;
    line-height: 4rem;
    /* font-family: "Open Sans", sans-serif; */
    font-family: "Montserrat", sans-serif;
    word-break: break-word;
    @media screen and (max-width: 480px) {
      font-size: 1.3rem;
    }
  }
  h4.designation {
    color: var(--pink-brand-color);
    font-size: 2rem;
    font-weight: 800;
    line-height: 4rem;
    @media (min-width: 992px) {
      font-size: 3.375rem;
    }
  }
`;
const ProfileImageContainer = styled(motion.div)`
  img {
    width: 394px;
    height: 394px;
    border: 15px solid #fff;
    border-radius: 50%;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1),
      0 20px 40px rgba(255, 255, 255, 0.15);
    @media screen and (max-width: 768px) {
      width: 80vw;
      height: 80vw;
      margin: 0 auto;
    }
  }
`;

const StyledLink = styled.a`
  background: linear-gradient(180deg, #01cdb5 0%, #4af094 100%);
  box-shadow: 1px 2px 13px rgba(194, 194, 194, 0.71);
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.07em;
  color: #fff;
  width: max-content;
  padding: 0.8rem 2rem;
  margin-top: 0.685rem;
`;

export default HomeIntroSection;
