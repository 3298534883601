import React from "react";
//Components
import { Container } from "../../styles/GlobalLayout";
//Icons
import { FiLayers, FiMonitor, FiMail, FiTool, FiEdit } from "react-icons/fi";

//Styles
import styled from "styled-components";
//Animation
import { motion } from "framer-motion";

const ServiceList = [
  {
    icon: <FiLayers />,
    title: "Website Development",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiMonitor />,
    title: "Mobile App Development",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiMail />,
    title: "Emailer Development",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiTool />,
    title: "Bug Fixes",
    description:
      "I throw myself down among the tallgrass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiEdit />,
    title: "Custom Order",
    description:
      "I throw myself down among the tallgrass by the stream as I lie close to the earth.",
  },
];

const HomeServicesSection = () => {
  return (
    <StyledServicesSection>
      <StyledContainer>
        <h4>what i do?</h4>
        <h2>how can i help your next project</h2>
        <Cards>
          {ServiceList.map((val, i) => (
            <Card key={i}>
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h3 className="title">{val.title}</h3>
                <p>{val.description}</p>
              </div>
            </Card>
          ))}
        </Cards>
      </StyledContainer>
    </StyledServicesSection>
  );
};

const StyledServicesSection = styled(motion.section)`
  background-color: var(--gray-background-color);
`;
const StyledContainer = styled(Container)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  & > h4 {
    color: #333;
    background-color: var(--primary-brand-color);
    font-size: 1rem;
    padding: 0.2rem 0.8rem;
    width: max-content;
    text-align: center;
    font-weight: normal;
    text-transform: capitalize;
    border-radius: 2px;
  }
  h2 {
    text-align: center;
    margin-top: 1rem;
    text-transform: capitalize;
    margin-bottom: 1.7rem;
    @media screen and (max-width: 991px) {
      font-size: 1.8rem;
      padding: 0rem 1rem;
    }
  }
`;
const Cards = styled(motion.div)`
  min-height: 20vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(320px - 40px), 1.2fr));
  grid-column-gap: 1.3rem;
  grid-row-gap: 0.2rem;
  width: 100%;
  margin: 0 auto;
  @media (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1.2fr));
  }
`;
const Card = styled(motion.div)`
  min-height: 20vh;
  cursor: pointer;
  padding: 2rem 2rem;
  position: relative;
  border-radius: 10px;
  transition: all 0.3s ease;
  @media screen and (max-width: 991px) {
    width: calc(100% - 20px);
    margin: 0.4rem auto;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(to right, #f61b10, #ef0963);
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
  }
  &:hover {
    box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }
  &:hover::before {
    opacity: 1;
    visibility: visible;
  }
  &:hover .icon > svg,
  &:hover h3,
  &:hover p {
    color: #fff;
  }
  & > .icon {
    font-size: 3rem;
    line-height: 3rem;
  }
  & > .icon > svg {
    color: var(--pink-brand-color);
    stroke-width: 1;
  }
  h3 {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.8rem;
  }
`;

export default HomeServicesSection;
