import React from "react";
//Components
import HomeIntroSection from "../components/Home/HomeIntroSection";
import HomeAboutSection from "../components/Home/HomeAboutSection";
import HomeServicesSection from "../components/Home/HomeServicesSection";
import HomeResumeSection from "../components/Home/HomeResumeSection";
import HomePortfolioSection from "../components/Home/HomePortfolioSection";
import HomeHireMe from "../components/Home/HomeHireMe";
import Footer from "../components/Footer/Footer";

//Styles
import styled from "styled-components";

//Animation
import { motion } from "framer-motion";
import { pageAnimation } from "../Animation";

const Home = () => {
  return (
    <motion.div
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <HomeIntroSection />
      <HomeAboutSection />
      <HomeServicesSection />
      <HomeResumeSection />
      <HomePortfolioSection />
      <HomeHireMe />
      <Footer />
    </motion.div>
  );
};

export default Home;
