import React from "react";
//Styles
import styled from "styled-components";
import { motion } from "framer-motion";

const EducationContent = [
  {
    id: 1,
    course: "The Creative React & Redux Course",
    college: "DevelopedByEd",
    college_link: "https://developedbyed.com",
    session: {
      end: 2021,
    },
    description:
      "Learnt React, Redux, framer-motion, react-router-dom, styled-components and was facinated by it.",
  },
  {
    id: 2,
    course: "Android N: From Beginner to Paid Professional",
    college: "Udemy",
    college_link: "https://udemy.com",
    session: {
      end: 2017,
    },
    description: "Learnt Android App Development.",
  },
  {
    id: 3,
    course: "Mastering Mobile App Design With Sketch 3",
    college: "Udemy",
    college_link: "https://udemy.com",
    session: {
      end: 2017,
    },
    description: "Learnt about UI/UX from this course.",
  },
  {
    id: 4,
    course: "Bachelor of Computer Application",
    college: "Acharya Institute of Technology",
    college_link: "https://acharya.ac.in",
    session: {
      start: 2011,
      end: 2015,
    },
    description:
      "I did my graduation here in Bangalore and got better in programming.",
  },
];
const ExperienceContent = [
  {
    id: 1,
    designation: "Full Stack Web Developer",
    company: "Freelance",
    current: true,
    work: {
      start: 2015,
      end: 2021,
    },
    description:
      "Built websites and fixed bugs based on the requirements of the clients.",
  },
  {
    id: 2,
    designation: "Full Stack Developer",
    company: "Kuhipaat Communications and Technology Pvt. Ltd",
    current: false,
    work: {
      start: 2019,
      end: 2021,
    },
    description:
      "Learnt WordPress, Codeigniter and developed sites for clients based on their requirements. Built API to send SMS, GMB system to fetch Google reviews, features for online payment and helped in solving complex problems.",
  },
  // {
  //   id: 3,
  //   designation: "Android App Developer",
  //   company: "Freelance",
  //   current: false,
  //   work: {
  //     start: 2013,
  //     end: 2015,
  //   },
  //   description:
  //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iure, ab maiores molestias repellendus vero facilis aut natus sunt obcaecati aliquam!",
  // },
  // {
  //   id: 4,
  //   designation: "iOS App Developer",
  //   company: "Freelance",
  //   current: false,
  //   work: {
  //     start: 2013,
  //     end: 2015,
  //   },
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis quasi, ad voluptatem voluptatibus, iste magni itaque eaque animi minima quibusdam nemo deserunt consectetur eos possimus, dicta repudiandae illo quia voluptas!",
  // },
];

const SkillList = [
  {
    id: 1,
    name: "HTML/CSS",
    percentage: 95,
  },
  {
    id: 2,
    name: "SASS",
    percentage: 90,
  },
  {
    id: 3,
    name: "Javascript",
    percentage: 95,
  },
  {
    id: 4,
    name: "jQuery",
    percentage: 90,
  },
  {
    id: 5,
    name: "Bootstrap",
    percentage: 99,
  },
  {
    id: 6,
    name: "PHP",
    percentage: 90,
  },
  {
    id: 7,
    name: "MySQL",
    percentage: 85,
  },
  {
    id: 8,
    name: "Git",
    percentage: 80,
  },
  {
    id: 9,
    name: "React",
    percentage: 40,
  },
  {
    id: 10,
    name: "Nodejs",
    percentage: 40,
  },
  {
    id: 11,
    name: "Express",
    percentage: 40,
  },
  {
    id: 12,
    name: "Tailwind CSS",
    percentage: 60,
  },
  {
    id: 13,
    name: "Nextjs",
    percentage: 60,
  },
  {
    id: 14,
    name: "Gulp",
    percentage: 60,
  },
];
const HomeResumeSection = () => {
  let SkillListLength = Math.floor(SkillList.length / 2);
  // console.log(`Skill List Length: ${SkillListLength}`);
  return (
    <ResumeSection>
      <SectionContainer className="">
        <h4>resume</h4>
        <h2>a summary of my resume</h2>
        <ResumeDetailsContainer className="">
          <ResumeDetails className="">
            <h3>My Education</h3>
            <ResumeDetailsContent>
              {EducationContent.map((item) => (
                <div key={item.id}>
                  <h5>{item.course}</h5>
                  <h5>
                    <a
                      href={item.college_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{`${item.college} / ${
                      item.session.start ? item.session.start + " - " : ""
                    }${item.session.end}`}</a>
                  </h5>
                  {/* <p>{item.description}</p> */}
                  <p>{item.description}</p>
                  {EducationContent.length !== item.id && <hr />}
                </div>
              ))}
            </ResumeDetailsContent>
          </ResumeDetails>
          <ResumeDetails>
            <h3>My Experience</h3>
            <ResumeDetailsContent>
              {ExperienceContent.map((item) => (
                <div key={item.id}>
                  <h5>{item.designation}</h5>
                  <h5>{`${item.company} / ${item.work.start} - ${
                    item.current ? "Present" : item.work.end
                  }`}</h5>
                  <p>{item.description}</p>
                  {ExperienceContent.length !== item.id && <hr />}
                </div>
              ))}
            </ResumeDetailsContent>
          </ResumeDetails>
        </ResumeDetailsContainer>
        <ResumeDetailsContainer>
          <ResumeDetails>
            <h3>My Skills</h3>
            <SkillContainer>
              {SkillList.map((skill, index) => (
                <div key={skill.id}>
                  {index < SkillListLength && (
                    <div className="skill">
                      <div className="skill-name">
                        <h5>{skill.name}</h5>
                        <h5>{`${skill.percentage}%`}</h5>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: `${skill.percentage}%` }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </SkillContainer>
          </ResumeDetails>
          <ResumeDetails>
            <h3>&nbsp;</h3>
            <SkillContainer>
              {SkillList.map((skill, index) => (
                <div key={skill.id} data-value={index}>
                  {index >= Math.floor(SkillList.length / 2) && (
                    <div className="skill" data-value={index}>
                      <div className="skill-name">
                        <h5>{skill.name}</h5>
                        <h5>{`${skill.percentage}%`}</h5>
                      </div>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          style={{ width: `${skill.percentage}%` }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </SkillContainer>
          </ResumeDetails>
        </ResumeDetailsContainer>
      </SectionContainer>
    </ResumeSection>
  );
};
const ResumeSection = styled(motion.section)`
  background-color: var(--dark-background-color);
`;
const SectionContainer = styled(motion.div)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  & > h4 {
    color: #333;
    background-color: var(--primary-brand-color);
    font-size: 1rem;
    padding: 0.2rem 0.8rem;
    width: max-content;
    text-align: center;
    font-weight: normal;
    text-transform: capitalize;
    border-radius: 2px;
  }
  h2 {
    color: #fff;
    text-align: center;
    margin-top: 1rem;
    text-transform: capitalize;
    margin-bottom: 1.7rem;
    @media screen and (max-width: 991px) {
      font-size: 1.8rem;
      padding: 0 1rem;
    }
  }
`;
const ResumeDetailsContainer = styled(motion.div)`
  display: grid;
  /* grid-template-columns: minmax(320px, 1fr) minmax(320px, 1fr);  */
  grid-template-columns: minmax(calc(320px - 1.5rem), 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  width: 100%;
  padding: 0rem 1.5rem;
  margin: 0 auto;
  @media (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    padding: 0rem 2rem;
    max-width: 1260px;
  }
`;
const ResumeDetails = styled(motion.div)`
  color: #fff;
  & > h3 {
    font-size: 1.75rem;
    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }
  @media screen and (max-width: 991px) {
    width: calc(100% - 2rem) !important;
  }
`;

const SkillContainer = styled(motion.div)`
  margin: 1rem 0rem;
  div.skill {
    margin: 1rem 0rem;
  }
  div.skill > .skill-name {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 0rem;
    grid-row-gap: 1rem;
    h5 {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    & > h5:nth-child(2) {
      text-align: right;
      letter-spacing: normal;
    }
  }
  div.skill > .progress {
    height: 0.5rem;
    background-color: #eee;
    width: 100%;
    border-radius: 2px;
    & > .progress-bar {
      width: 70%;
      height: 100%;
      background: linear-gradient(to right, #ff006b, #f70699);
    }
  }
`;

const ResumeDetailsContent = styled(motion.div)`
  position: relative;
  padding: 0rem 0.16rem 0rem 1rem;
  margin: 2rem 0rem;
  &::before {
    content: "";
    border-left: 2px solid var(--pink-brand-color);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  & > div {
    & > h5:first-child {
      font-size: 1.3rem;
      margin-bottom: 0.5rem;
      font-weight: 600;
    }
    & > h5:nth-child(2),
    & > p {
      font-size: 1rem;
      line-height: 1.8;
    }
    & > h5:nth-child(2) {
      margin-bottom: 0.5rem;
      font-weight: 500;
      & > a {
        cursor: pointer;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          display: inline-block;
          bottom: -30%;
          left: 0;
          width: 0%;
          background-color: var(--primary-brand-color);
          height: 0.25rem;
          transition: width 0.5s ease;
        }
        &:hover::before {
          width: 100%;
        }
      }
    }
    & > p {
      margin-bottom: 1rem;
      color: #ddd;
      letter-spacing: normal;
    }
    & hr {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
`;
export default HomeResumeSection;
