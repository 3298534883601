import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root{
    --primary-brand-color:#4AF094;
    --secondary-brand-color:#0B1753;
    --pink-brand-color:#f5344c;
    --dark-background-color:#191919;
    --gray-background-color:#f8f9fc;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  }
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body{
    /* background-color: #0B1753; */
    font-family: 'Poppins',sans-serif;
    letter-spacing: 1.5px;
    -moz-osx-font-smoothing:grayscale;
  }
  ::selection{
    background:var(--primary-brand-color);
    color:#fff;
  }
  a{
    text-decoration: none;
    color:#fff;
  }
  section{
    min-height:700px;
    padding:3rem 0rem;
  }
  h2{
    font-size:2.5rem;
  }
  .portfolio{
    position:relative;
    min-height: 500px;
    width:100%;
    padding-top:35%;
  }
  .portfolio .thumbnail-inner {
    transition: transform .28s ease;
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
  }
  .portfolio .thumbnail-inner::before {
      background-color: #f6004c;
      background-image: linear-gradient(#f6004c 10%, #000 100%);
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      opacity: 0;
  }
  .portfolio:hover .thumbnail-inner::before {
      opacity: 0.85;
  }
  .portfolio:hover .thumbnail-inner {
      transform: scale(1.08) translateZ(0);
  }
  .portfolio .thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 5px;
      background-color: #0a0a0a;
      background-image: url(/img/portfolio-1.jpg);
      transform: scale(1.13) translateZ(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: transform .28s ease;
      z-index: 4;
  }
  .portfolio:hover .thumbnail::after {
      background-color: rgba(25,25,25,0.24);
  }
  .portfolio:hover .thumbnail {
      transform: scale(1) translateZ(0);
  }
  .portfolio .thumbnail.image-1{
    background-image:url(/img/portfolio-1.jpg);
  }
  .portfolio:hover .bg-blr-image {
      opacity: 0;
      z-index: 9;
  }
  .portfolio .bg-blr-image.image-1 {
      background-image: url(/img/portfolio-1.jpg);
  }
  .portfolio .bg-blr-image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-color: #0a0a0a;
      background-image: url(/img/portfolio-1.jpg);
      transform: translateY(38px);
      opacity: 0;
      z-index: 1;
  }
  .portfolio.text-center .content {
      position: absolute;
      bottom: 40px;
      left: 40px;
      max-width: 80% !important;
      z-index: 10;
      padding: 0;
      right: 40px;
      margin: 0 auto;
  }
  .portfolio .content .inner p {
      color: #c6c9d8;
      font-size: 14px;
  }
  .portfolio .content .inner h4 {
      font-size: 24px;
      line-height: 36px;
  }
  .portfolio .content .inner h4 a {
      color: #ffffff;
  }
  .portfolio .content .inner .portfolio-button {
      margin-top: 35px;
      transition: 0.7s;
  }
  .portfolio .content .inner .portfolio-button a.rn-btn {
      color: #ffffff;
      border-color: rgba(255,255,255,0.3);
  }
  a.rn-btn, button.rn-btn {
      padding: 0 23px;
      height: 40px;
      display: inline-block;
      line-height: 34px;
      border: 2px solid #f9004d;
      border-radius: 4px;
      font-size: 14px;
      position: relative;
      z-index: 2;
      color: #f9004d;
      letter-spacing: 0.2px;
      text-transform: uppercase;
  }
  .portfolio .content .inner .portfolio-button a.rn-btn:hover {
      border-color: #f9004d;
  }
  a.rn-btn:hover, button.rn-btn:hover {
      border: 2px solid #f9004d;
      transform: translateY(-3px);
      color: #ffffff;
      background: #f9004d;
  }
  .link-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: block;
      z-index: 9;
  }
  //Utilities
  .rounded-circle {
    border-radius:50%;
  }
  .text-center{
    text-align: center;
  }
  .mt--40{
    margin-top:40px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .col-12 {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.grid{
  display:grid;
}
.grid-col-1{
  grid-template-columns:minmax(320px,1fr);
}
.grid-col-2{
  grid-template-columns:minmax(320px,1fr) minmax(320px,1fr);
}
.grid-col-3{
  grid-template-columns:minmax(320px,1fr) minmax(320px,1fr) minmax(320px,1fr);
}
.grid-col-gap-1{
  grid-column-gap: 0.25rem;
}
.grid-col-gap-2{
  grid-column-gap:calc(0.25rem * 2);
}
.grid-col-gap-3{
  grid-column-gap:calc(0.25rem * 3);
}
.grid-col-gap-4{
  grid-column-gap:calc(0.25rem * 4);
}
.grid-col-gap-5{
  grid-column-gap:calc(0.25rem * 5);
}
.grid-col-gap-6{
  grid-column-gap:calc(0.25rem * 6);
}
.grid-row-gap-1{
  grid-row-gap: 0.25rem;
}
.grid-row-gap-2{
  grid-row-gap: 0.5rem;
}

.container{
  width:100%;
}

  //Media Queries
  @media (min-width:1281px){
    .container{
      max-width:1536px;
    }
  }
  @media (min-width:1025px) and (max-width:1280px){
    .container{
      max-width: 1280px;
    }
  }
  @media (min-width:769px) and (max-width:1024px){
    .container{
      max-width: 1024px;
    }
  }
  
  @media (min-width: 992px) {
    .col-lg-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }
  @media (min-width: 768px) {
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @media (min-width:641px) and (max-width:768px){
    .container{
      max-width: 768px;
    }
  }
  @media (min-width:576px) and (max-width:640px){
    .container{
      max-width: 640px;
    }
  }
  @media (min-width: 576px) {
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
 
`;

export default GlobalStyles;
