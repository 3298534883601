import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <StyledFooter>
      <FooterContainer>
        <p>Copyright &copy; {new Date().getFullYear()}. All Rights Reserved.</p>
      </FooterContainer>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  background-color: var(--dark-background-color);
  color: #fff;
  padding: 1rem 2rem;
  p {
    text-align: center;
  }
`;
const FooterContainer = styled.div`
  margin: 0 auto;
  @media (min-width: 992px) {
    max-width: 1260px;
  }
`;

export default Footer;
