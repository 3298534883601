import React from "react";
//Styles
import styled from "styled-components";
import { Container } from "../../styles/GlobalLayout";
//Animation
import { motion } from "framer-motion";
const sign = "";
const no_of_years = () => {
  return new Date().getFullYear() - 2015;
};
const HomeAboutSection = () => {
  return (
    <StyledAboutMe>
      <h4>about me</h4>
      <h2>know me more</h2>
      <AboutMeDescription>
        <DescriptionContainer>
          <h3>
            Hi, I'm{" "}
            <LineUnderText>
              <span>Binit Demta</span>
            </LineUnderText>
          </h3>
          <p>
            I'm a Full Stack Web Developer with a passion for Web Development. I
            enjoy developing simple, clean and sleek websites, as it provides
            real value to the end user. Delivering work within time with a good
            budget and providing a good quality website and service is my moto.
          </p>
        </DescriptionContainer>
        <DescriptionContainer>
          <h4 className="years">{no_of_years()}</h4>
          <h3 className="experience">
            Years of <b>Experience</b>
          </h3>
        </DescriptionContainer>
        <InfoList>
          <li>
            <span>Name:</span>Binit Demta
          </li>
          <li>
            <span>Email:</span>contact@binitdev.tech
          </li>
          <li>
            <span>Date of Birth:</span>9 November, 1991
          </li>
          <li>
            <span>From:</span>Assam, India
          </li>
        </InfoList>
      </AboutMeDescription>
    </StyledAboutMe>
  );
};

const StyledAboutMe = styled(motion.section)`
  background-color: var(--dark-background-color);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  min-height: 400px;
  padding: 3rem 0.5rem;
  & > h4 {
    color: #333;
    background-color: var(--primary-brand-color);
    font-size: 1rem;
    padding: 0.2rem 0.8rem;
    width: max-content;
    text-align: center;
    font-weight: normal;
    text-transform: capitalize;
    border-radius: 2px;
  }
  h2 {
    color: #fff;
    text-align: center;
    margin-top: 1rem;
    text-transform: capitalize;
    margin-bottom: 1.7rem;
  }
  h3,
  p {
    color: #fff;
  }
  h3 {
    font-size: 2rem;
  }
  @media (min-width: 992px) {
    padding: 3rem 0rem;
  }
  @media screen and (max-width: 991px) {
    h2 {
      font-size: 1.8rem;
      padding: 0rem 1rem;
    }
    h3 {
      font-size: 1.3rem;
    }
  }
`;
const AboutMeDescription = styled(Container)`
  display: grid;
  padding: 0 1rem;
  grid-template-columns: minmax(calc(320px - 40px), 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  @media (min-width: 992px) {
    grid-template-columns: minmax(320px, 0.7fr) minmax(320px, 0.4fr);
    padding: 0 3rem;
  }
`;
const LineUnderText = styled.span`
  position: relative;
  span::before {
    content: "";
    width: 98%;
    height: 0.18rem;
    background-color: var(--primary-brand-color);
    position: absolute;
    bottom: 0.15rem;
    left: 0.5%;
    @media (min-width: 992px) {
      height: 0.2rem;
    }
  }
`;

const DescriptionContainer = styled(motion.div)`
  h4.years {
    font-size: 7rem;
    position: relative;
    margin: 0 auto;
    color: var(--pink-brand-color);
    width: max-content;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    &::after {
      content: "${sign}";
      font-size: 0.7em;
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  h3.experience {
    font-size: 1.5rem;
    text-align: center;
    b {
      color: var(--primary-brand-color);
    }
  }
  & > p {
    margin-top: 0.5rem;
  }
`;

const InfoListContainer = styled(Container)`
  display: grid;
  padding: 1rem 0rem;
  grid-template-columns: minmax(320px, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  width: 100%;
`;

const InfoList = styled(motion.ul)`
  display: flex;
  justify-content: space-between;
  align-items: start;
  color: #fff;
  flex-direction: column;
  list-style: none;
  padding: 0rem 0.5rem;
  span {
    display: block;
    font-weight: 700;
    color: #dddbdb;
  }
  li {
    display: inline-block;
    font-weight: 700;
    margin-top: 0.5rem;
  }
  @media (min-width: 568px) {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(35%, 1fr));
    padding: 0rem 0.2rem;
  }
  @media (min-width: 992px) {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    /* display: grid;
    grid-template-columns:
      minmax(290px, 0.2fr) minmax(290px, 0.2fr) minmax(290px, 0.2fr)
      minmax(290px, 0.2fr); */
    padding: 0rem;
    li {
      margin-top: auto;
    }
  }
`;

export default HomeAboutSection;
