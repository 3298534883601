export const pageAnimation = {
  hidden: {
    opacity: 0,
    y: 50,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.75,
    },
  },
};
export const titleAnim = {
  hidden: { opacity: 0, transition: { duration: 2, ease: "easeOut" } },
  show: {
    opacity: 1,
    transition: { duration: 2, ease: "easeOut" },
  },
};
